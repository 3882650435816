<template>
  <div class="v2-guest-container" ref="live" id="live">
    <live-header ref="header" id="header" :meetingInfo="meetingInfo">
      <div class="v2-guest-header-block">
        <!-- <live-room-play
          :version="stream.play.version"
          :liveAppID="1320573990"
          :playUrl="stream.play.playUrl"
          orientation="vertical"
          objectFit="fillCrop"
          minCache="1"
          maxCache="3"
          mode="live"
          soundMode="speaker"
          :muted="false"
          :debug="stream.play.debug"
          :autoplay="false"
          @PlayEvent="onPlayEvent"
        >
        </live-room-play> -->
        <HlsPlayer ref="h5Player" @loaded="loaded"></HlsPlayer>
        <!-- <img
          v-if="!meetingInfo.isPlaying"
          class="v2-guest-header-cover global_container_mask"
          :src="require('@/assets/images/live/head-cover.jpg')"
        />
      <img
        v-if="meetingInfo.meet_info.status >=2"
        class="v2-guest-header-cover global_container_mask"
        :src="require('@/assets/images/live/head-end-cover.jpg')"
        mode="widthFix"
      /> -->
      </div>
    </live-header>
    <div class="v2-guest-courseware-main" ref="main" id="main">
      <Player ref="player" :meetingInfo="meetingInfo" v-if="!isMoreThanEndPage()"></Player>
      <div
        v-if="isMoreThanEndPage() && isCurrStep('discuss')"
        class="v2-guest-header-cover global-div-center"
      >
        <img
          :src="require('@/assets/images/live/discuss.jpg')"
          mode="widthFix"
        />
      </div>
      <img
        v-if="!meetingInfo.isPlaying && meetingInfo.meet_info.status < 2"
        class="v2-guest-header-cover global_container_mask"
        :src="require('@/assets/images/live/head-cover.jpg')"
        mode="widthFix"
      />
      <img
        v-if="meetingInfo.meet_info.status >=2"
        class="v2-guest-header-cover global_container_mask"
        :src="require('@/assets/images/live/head-end-cover.jpg')"
        mode="widthFix"
      />
    </div>
    <div class="v2-guest-banner" ref="banner" id="banner">
      <Banner
        :meetingInfo="meetingInfo"
        @changeBanner="changeBanner"
        :isGuest="true"
      ></Banner>
    </div>
    <div
      class="v2-guest-content"
      ref="content"
      id="content"
    >
      <div
        class="v2-guest-scroll"
        id="contentItem"
        ref="contentItem"
      >
        <live-text
          ref="coursePage"
          id="coursePage"
          v-if="meetingInfo.tab == 'text'"
          :meetingInfo="meetingInfo"
        ></live-text>
        <live-chat
          ref="coursePage"
          id="coursePage"
          @sendMessage="sendChat"
          v-if="meetingInfo.tab == 'chat'"
          :meetingInfo="meetingInfo"
        ></live-chat>
        <live-info
          ref="coursePage"
          id="coursePage"
          @sendMessage="sendChat"
          v-else-if="meetingInfo.tab == 'info'"
          :meetingInfo="meetingInfo"
        ></live-info>
      </div>
    </div>
    <div class="v2-guest-footer" ref="footer" id="footer" v-if="meetingInfo.tab == 'chat'">
      <CustomSelect :meetingInfo="meetingInfo" @sendMessage="sendChat" ref="select"></CustomSelect>
    </div>
    <MeetingTip
      :cfg="meetingMsg"
      @doSelect="doSelect"
      v-if="showMeetingTip"
      class="global-full-screen global_container_popup"
    >
      <div class="live-meeting-tip">
        <div
          class="live-meeting-li"
          v-for="(item, index) in meetingMsg.content"
          :key="index"
        >
          <span
            v-for="(text, textIndex) in item"
            :key="text"
            :style="meetingMsg?.style?.[index]?.[textIndex] || ''"
            >{{ text }}</span
          >
        </div>
      </div>
    </MeetingTip>
  </div>
</template>
<script>
import LiveInfo from "@/components/page/live/components/live-info";
import LiveChat from "@/components/page/live/components/live-chat";
import LiveHeader from "@/components/page/live/components/live-header";
import Banner from "@/components/page/live/components/banner";
import CustomSelect from "@/components/page/live/components/select";
import LiveText from "@/components/page/live/components/live-text";
import Player from "@/components/page/live/components/player";
import MeetingTip from "@/components/page/live/components/Popup";
import H5Player from '@/components/player/H5Player'
import DPlayer from '@/components/player/DPlayer'
import HlsPlayer from '@/components/player/HlsPlayer'

// import info from "@/vendor/info";
import websocket from "@/components/page/live/vendor/websocket";
import dom from "@/components/page/live/vendor/dom";
import action from "@/components/page/live/vendor/action";
import events from "@/components/page/live/vendor/events";
import stream from "@/components/page/live/vendor/stream";
import get from "@/components/page/live/vendor/get";
import distinguish from "@/components/page/live/vendor/distinguish";
import popup from "@/components/page/live/vendor/popup";

export default {
  name: "live",
  mixins: [websocket, dom, action, events, stream, get, distinguish,popup],
  components: {
    CustomSelect,
    LiveHeader,
    LiveInfo,
    MeetingTip,
    LiveText,
    Banner,
    Player,
    LiveChat,
    H5Player,
    DPlayer,
    HlsPlayer,
  },
  data: () => {
    return {
      info: {},
      meetingInfo: {
        meeting_number: "",
        doctor_index: 1,
        guest_index: 1,
        isPlaying: false,
        role: 0, // 0 观众 1 专家
        netStatus: 1, // 网路状态 0 好 1 一般 2 优秀
        count: 0, // 人数
        local_tm: 0, //本地时间
        server_tm: 0, //服务器时间
        class_info: [],
        meet_info: [],
        // 本地自定义
        tab: "chat", // text题词 chat聊天 info基本信息
        isPushing: false,
      },
      showDownTime: 0,
      scrollTop: 1,
    };
  },

  created() {
    this.meetingInfo.id = this.$route.query.id
    this.isSign((status) => {
      if(status){
        this.dealWithNetwork({status:false})
        return;
      }
      this.initPage();
    });
  },
  // onLoad: function (options) {
  //   this.meetingInfo.meeting_number = options.meeting_number;
  // },
  beforeUnmount() {
    this.destroyAll();
  },
  methods: {
    destroyAll() {
      this.changeIndex();
      this.closeWebsocket();
      this.clearSignInfo();
    },
    // 进入重新初始化所有东西
    initPage() {
      this.runTimer();
      this.$nextTick(() => {
        this.initHeight();
      });
      this.createPlayInstance();
      this.initWebsocket();
    },
    initMeeting() {
      this.meetingInfo.is_test = 0;
      this.meetingInfo.doctor_index = 1;
      this.meetingInfo.guest_index = 1;
    },
    // 改变模块
    changeBanner(tab) {
      this.$refs.h5Player.play()
      console.log(tab)
      this.meetingInfo.tab = tab;
      this.$nextTick(() => {
        this.$refs.select && this.$refs.select.closeList()
        if (tab == "chat") {
          this.initScrollBottom();
        } else {
          this.initScrollTop();
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "./index.scss";
</style>
