<template>
    <img :src="this.src"  alt="Image" :class="`lazyload-${index} lazyload` ">
</template>
<script>

import lozad from 'lozad';
export default {
    name:"PPTImage",
    props:{
        src:String,
        index:Number
    },
    data:()=>{
        return {
            image:"https://files-med.oss-cn-zhangjiakou.aliyuncs.com/common/white-loading.jpg"
        }
    },
    created(){
       
    },
    methods:{
      
    },
}

</script>
<style>
.ppt-image-container {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.lazyload {
  /* opacity: 0; */
  /* transition: opacity 0.5s ease-in-out; */
}

.lazyload.loaded {
  opacity: 1;
}
</style>