<template>
  <div class="h5-player-container" id="h5-player"></div>
</template>
<script>

export default {
  name: 'h5-player',
  props: {
  },
  data: () => {
    return {
      url: '',
      type: "application/x-mpegURL",
      player: null,
      timer: null,
      isPlayEnd: false,
      options: {
        techOrder: ['html5'],
        html5:{
          hls:{
            withCredentials:true
          }
        },
        playsinline:true,
        autoplay:false,
        liveui:true,
        controls: false,//用户可以与之交互的控件
        // loop:true,//视频一结束就重新开始
        predload: 'metadata',
        muted: true,//默认情况下将使所有音频静音
        aspectRatio: "1:1",//显示比率
        sources: [
          {
            src: '',
            // type: 'application/x-mpegURL'
          }
        ]
      },
      showVideo: false,
      currTime: 0,
      isFirst: true,
      isLoaded:false,
      played:false,
      isCanPlay:false,
      isExec:false
    }
  },
  mounted() {
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose()
    }
  },
  methods: {
    getStatus() {
      return this.player ? this.player.paused() : false;
    },
    play(status) {
      this.$nextTick(() => {
        this.player.play();
        // this.player.currentTime(20)
        this.showCover = false
        this.showControl = false;
        this.$emit("beginVideo")
      })
    },
    playDefault() {
      this.$nextTick(() => {
        this.player.play();
        this.showCover = true
      })

    },
    pause() {
      this.player.pause();
    },
    toggleControl() {
      this.showControl = !this.showControl;
    },
    setControl(status) {
      this.showControl = status;
    },
    toggle() {
      let key = this.getStatus() ? 'play' : 'pause';
      this[key]();
      this.index++;
    },
    fullScreen() {
      this.isFullscreen = this.player.isFullscreen();
      if (!this.isFullscreen) {
        this.player.requestFullscreen()
      } else {
        this.player.exitFullscreen();
      }
    },
    switch(url, currTime = 0) {
      if (!url) return;
      if(this.$tools.isIos() && this.isExec && !this.isMp4(url)) return;
      this.isExec = false;
      let type = url.includes('mp4') ? 'video/mp4' : "application/x-mpegURL"
      let that = this;
      let _symbel = url.includes("?") ? "&" : "?"
      // let videoURL = `${url}${_symbel}random=${Math.random()}`
      let videoURL = this.url
      // if(this.url == url && this.$tools.isIos()) {
      //   if(!this.isLoaded || this.played) return;
      //   this.played = true;
      //   setTimeout(()=>{
      //     console.log(this.url)
      //     this.player.src({
      //       src:videoURL,
      //       // type
      //     });
      //     this.player.ready(() => {
      //       that.loaded()
      //     });
      //     setTimeout(()=>{
      //       this.played = false;
      //     },2000)
      //   },15*1000)
      //   return;
      // }
      try {
        this.player && this.player.dispose()
        this.player = null
      } catch (err) {
        this.player = null
      }
      this.playerId = `player${Math.floor(Math.random() * 1000000)}`
      this.showVideo = false
    //   document.getElementById('h5-player').innerHTML = `<div class='h5-player-video-main'  ref='videoMain' style="width:100%;height:auto;">
    //   <video ref="player"  id="${this.playerId}" style="width:100%;height:auto;" class='h5-player-video  video-js vjs-big-play-centered vjs-fluid'
    //     x5-video-player-type="h5-page" preload="auto" playsinline="true" webkit-playsinline="true" allowsInlineMediaPlayback="true" muted="true"
    //     x-webkit-airplay="true" x5-video-orientation="portraint" x5-video-player-fullscreen=""><source src="${videoURL}"  /> 
    //   </video>

    // </div>`
      document.getElementById('h5-player').innerHTML = `<div class='h5-player-video-main'  ref='videoMain' style="width:100%;height:auto;">
      <video ref="player"  id="${this.playerId}" style="width:100%;height:auto;" class='h5-player-video  video-js vjs-big-play-centered vjs-fluid'
        x5-video-player-type="h5-page" preload="auto" playsinline="true" webkit-playsinline="true" allowsInlineMediaPlayback="true" muted="true"
        x-webkit-airplay="true" x5-video-orientation="portraint" x5-video-player-fullscreen=""><source src="${videoURL}" type="${type}" /> 
      </video>

    </div>`
      this.showVideo = true
      this.$nextTick(() => {
        this.url = url;
        this.currTime = currTime;
        this.type = type;
        this.options.sources[0].src = url;
        // this.options.sources[0].type = type;
        this.isPlayEnd = false;
        this.player = this.$video(`#${this.playerId}`, this.options, ()=>{

          if (this.isFirst) {
            this.player.pause && this.player.pause()
          }
          // if(this.$tools.isIos()) {
          //   this.load();
          // }
          
          if (!url.includes("mp4") ) {
            this.setMuted(true)
            setTimeout(() => {
              let promise = this.player.play()
              if(promise){
                promise.then(res=>{
                  console.log(res)
                }).catch(err=>{
                  if(this.$tools.isIos() && !this.isMp4(this.url)){
                    setTimeout(() => {
                      this.isExec = true
                      setTimeout(()=>{
                        this.loaded()
                        setTimeout(()=>{  this.isExec = false;},100)
                      },2000)
                      // this.loaded()
                    }, 10*1000)
                  }
                })
              }
            }, 2000)
          }
          // if(this.$tools.isIos() && !this.isMp4(this.url)){
          //   setTimeout(() => {
          //     this.loaded()
          //   }, 10*1000)
          // }
          this.player.on('timeupdate', this.timeupdate)
          this.player.on('ended', this.playEnd)
          this.player.on('loadedmetadata', ()=>{
            // this.loaded()
            if(!this.$tools.isIos()) {
              this.loaded()
            }else if(!this.isMp4(this.url)){
              this.isLoaded = true;
            }
          })
          // this.on('error', function () {
          //   console.log(222222222222)
          //   // that.loaded()
          // })
          // console.log(this.hls,"3333333333333333333")
          // this.hls.on('error', function(event, data) {
          //   console.error('HLS Error:', data);
          //   if (data.fatal) {
          //     switch (data.type) {
          //       case 'mediaError':
          //         console.log('Media Error, trying to recover');
          //         player.hls.recoverMediaError(); // 尝试恢复媒体错误
          //         break;
          //       case 'networkError':
          //         console.log('Network Error, trying to recover');
          //         player.src({src: player.currentSrc(), type: 'application/x-mpegURL'}); // 重新加载HLS源
          //         player.load();
          //         player.play();
          //         break;
          //       default:
          //         console.error('Fatal error, cannot recover:', data);
          //         break;
          //     }
          //   }
          // });
        })
      })

    },
    isMp4(url){
      return url.includes("mp4")
    },
    timeupdate() {
      this.$emit("updateTime", this.player.currentTime())
    },
    seek(time) {
      this.player.currentTime(time)
    },
    playEnd() {
      this.isPlayEnd = true;
      this.showCover = true
      this.$emit("playEnd")
    },
    fullscreenchange(e) {
      this.isFullscreen = this.player.isFullscreen();
      this.showControl = this.isFullscreen;
      // this.$refs.videoMain.children[0].appendChild(this.$refs.controlsMain)
      if (!this.$tools.isAndroid()) {
        setTimeout(() => {
          this.play()
        }, 1000)
      }
    },
    setMuted(status) {
      this.player && this.player.muted(status)
    },
    loaded() {
      if (this.currTime) {
        this.seek(this.currTime)
      }
      // this.play(true)
      this.$emit('loaded');
    },
  }
}
</script>
<style>
/* 时间进度条 */
.h5-player-container,
.h5-player-video {
  width: 100%;
  height: auto;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items:center;
}

/* .video-js.vjs-fluid:not(.vjs-audio-only-mode) {
  width: 100vw;
  height: 100vw;
} */

.h5-player-container video::-webkit-media-controls-timeline {
  display: none !important;
}

/* 全屏按钮 */
.h5-player-container video::-webkit-media-controls-fullscreen-button {
  display: none !important;
  content: "\e719";
}

/* 音量按钮 */
.h5-player-container video::-webkit-media-controls-mute-button {
  display: none !important;
}

.h5-player-video-main {
  position: relative;
  z-index: 5;
  height: auto;
  width: 100%;
}

.h5-player-video-main video {
  height: auto !important;
  width: 100% !important;
  position: absolute !important;
  left: 0;
  top: -25% !important;
}


</style>