<template>
  <div class="h5-player-container" id="h5-player">
    <div ref="player" style="height:2.666667rem;width:2.666667rem;"></div>
  </div>
</template>
<script>
import DPlayer from 'dplayer'
import Hls from 'hls.js'
export default {
  name: 'h5-player',
  props: {
  },
  data: () => {
    return {
      url: '',
      type: "application/x-mpegURL",
      player: null,
      timer: null,
      isPlayEnd: false,
      options: {
        techOrder: ['html5'],
        html5:{
          hls:{
            withCredentials:true
          }
        },
        playsinline:true,
        autoplay:false,
        liveui:true,
        controls: false,//用户可以与之交互的控件
        // loop:true,//视频一结束就重新开始
        predload: 'metadata',
        muted: true,//默认情况下将使所有音频静音
        aspectRatio: "1:1",//显示比率
        sources: [
          {
            src: '',
            // type: 'application/x-mpegURL'
          }
        ]
      },
      showVideo: false,
      currTime: 0,
      isFirst: true,
      isLoaded:false,
      played:false,
      isCanPlay:false,
      isExec:false
    }
  },
  mounted() {
  },
  beforeDestroy() {
    if (this.player) {
      this.player.destroy()
    }
  },
  methods: {
    getStatus() {
      return this.player ? this.player.paused() : false;
    },
    play(status) {
      this.$nextTick(() => {
        console.log(this.player)
        document.getElementsByTagName("video")[0].play()
        // this.player.play();
        console.log(7878787787878787)
        // this.player.currentTime(20)
        this.showCover = false
        this.showControl = false;
        this.$emit("beginVideo")
      })
    },
    playDefault() {
      this.$nextTick(() => {
        this.player.play();
        this.showCover = true
      })

    },
    pause() {
      this.player.pause();
    },
    toggleControl() {
      this.showControl = !this.showControl;
    },
    setControl(status) {
      this.showControl = status;
    },
    toggle() {
      let key = this.getStatus() ? 'play' : 'pause';
      this[key]();
      this.index++;
    },
    fullScreen() {
      this.isFullscreen = this.player.isFullscreen();
      if (!this.isFullscreen) {
        this.player.requestFullscreen()
      } else {
        this.player.exitFullscreen();
      }
    },
    switch(url, currTime = 0) {
      console.log(5555555555)
      if (!url) return;
      // if(this.$tools.isIos() && this.isExec && !this.isMp4(url)) return;
      this.isExec = false;
      let type = url.includes('mp4') ? 'video/mp4' : "application/x-mpegURL"
      let that = this;
      let _symbel = url.includes("?") ? "&" : "?"
      // let videoURL = `${url}${_symbel}random=${Math.random()}`
      let videoURL = this.url
      try {
        this.player && this.player.destroy()
        this.player = null
      } catch (err) {
        this.player = null
      }
      this.playerId = `player${Math.floor(Math.random() * 1000000)}`
      this.showVideo = false
    //   document.getElementById('h5-player').innerHTML = `<div class='h5-player-video-main'  ref='videoMain' style="width:100%;height:auto;">
    //   <video ref="player"  id="${this.playerId}" style="width:100%;height:auto;" class='h5-player-video  video-js vjs-big-play-centered vjs-fluid'
    //     x5-video-player-type="h5-page" preload="auto" playsinline="true" webkit-playsinline="true" allowsInlineMediaPlayback="true" muted="true"
    //     x-webkit-airplay="true" x5-video-orientation="portraint" x5-video-player-fullscreen=""><source src="${videoURL}"  /> 
    //   </video>

    // </div>`
    //   document.getElementById('h5-player').innerHTML = `<div class='h5-player-video-main'  ref='videoMain' style="width:100%;height:auto;">
    //   <video ref="player"  id="${this.playerId}" style="width:100%;height:auto;" class='h5-player-video  video-js vjs-big-play-centered vjs-fluid'
    //     x5-video-player-type="h5-page" preload="auto" playsinline="true" webkit-playsinline="true" allowsInlineMediaPlayback="true" muted="true"
    //     x-webkit-airplay="true" x5-video-orientation="portraint" x5-video-player-fullscreen=""><source src="${videoURL}" type="${type}" /> 
    //   </video>

    // </div>`
      this.showVideo = true
      this.$nextTick(() => {
        this.url = url;
        this.currTime = currTime;
        this.type = type;
        this.options.sources[0].src = url;
        // this.options.sources[0].type = type;
        this.isPlayEnd = false;
        
        console.log(4444444444444)
        this.player = new DPlayer({
            container: this.$refs.player,

            live: true,
                video: {
                url: this.url,
                type: 'customHls',
                customType: {
                    customHls: function (video, player) {
                      console.log('dndndndndndn')
                        const hls = new Hls();
                        hls.loadSource(video.src);
                        hls.attachMedia(video);
                    },
                },
            },
        });
        
        // this.player.on('timeupdate', this.timeupdate)
          this.player.on('ended', this.playEnd)
          console.log(3333333333)
          this.player.on('loadedmetadata', ()=>{
            // this.loaded()
            console.log(66666666666)
            this.loaded()
          })
      })

    },
    isMp4(url){
      return url.includes("mp4")
    },
    timeupdate() {
      this.$emit("updateTime", this.player.currentTime())
    },
    seek(time) {
      this.player.currentTime(time)
    },
    playEnd() {
      this.isPlayEnd = true;
      this.showCover = true
      this.$emit("playEnd")
    },
    fullscreenchange(e) {
      this.isFullscreen = this.player.isFullscreen();
      this.showControl = this.isFullscreen;
      // this.$refs.videoMain.children[0].appendChild(this.$refs.controlsMain)
      if (!this.$tools.isAndroid()) {
        setTimeout(() => {
          this.play()
        }, 1000)
      }
    },
    setMuted(status) {
      // this.player && this.player.muted(status)
    },
    loaded() {
      if (this.currTime) {
        this.seek(this.currTime)
      }
      this.play(true)
      this.$emit('loaded');
    },
  }
}
</script>
<style>
/* 时间进度条 */
.h5-player-container,
.h5-player-video {
  width: 100%;
  height: auto;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items:center;
}

/* .video-js.vjs-fluid:not(.vjs-audio-only-mode) {
  width: 100vw;
  height: 100vw;
} */

.dplayer-notice-list,.dplayer-controller-mask,.dplayer-mobile-play{
  display: none !important;
}
.dplayer-controller{
  display: none !important;;
}
.h5-player-container video::-webkit-media-controls-timeline {
  display: none !important;
}

/* 全屏按钮 */
.h5-player-container video::-webkit-media-controls-fullscreen-button {
  display: none !important;
  content: "\e719";
}

/* 音量按钮 */
.h5-player-container video::-webkit-media-controls-mute-button {
  display: none !important;
}

.h5-player-video-main {
  position: relative;
  z-index: 5;
  height: auto;
  width: 100%;
}

.h5-player-video-main video {
  height: auto !important;
  width: 100% !important;
  position: absolute !important;
  left: 0;
  top: -25% !important;
}


</style>