<template>
  <div class="h5-player-container" id="h5-player">
    <video ref="video"  id="${this.playerId}" style="width:100%;height:auto;" class='h5-player-video  video-js vjs-big-play-centered vjs-fluid'
        x5-video-player-type="h5-page" preload="auto" playsinline="true" webkit-playsinline="true" allowsInlineMediaPlayback="true" muted="true"
        x-webkit-airplay="true" x5-video-orientation="portraint" x5-video-player-fullscreen=""></video>
  </div>
</template>
<script>
import Hls from 'hls.js'
export default {
  name: 'h5-player',
  props: {
  },
  data: () => {
    return {
      url: '',
      type: "application/x-mpegURL",
      player: null,
      timer: null,
      isPlayEnd: false,
      options: {
        techOrder: ['html5'],
        html5:{
          hls:{
            withCredentials:true
          }
        },
        playsinline:true,
        autoplay:false,
        liveui:true,
        controls: false,//用户可以与之交互的控件
        // loop:true,//视频一结束就重新开始
        predload: 'metadata',
        muted: true,//默认情况下将使所有音频静音
        aspectRatio: "1:1",//显示比率
        sources: [
          {
            src: '',
            // type: 'application/x-mpegURL'
          }
        ]
      },
      showVideo: false,
      currTime: 0,
      isFirst: true,
      isLoaded:false,
      played:false,
      isCanPlay:false,
      isExec:false
    }
  },
  mounted() {
    // this.initPlayer()
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose()
    }
  },
  methods: {
    initPlayer(url){
      this.url = url;
      let video = this.$refs.video
      this.player = new Hls({debug:true});
      this.player.attachMedia(this.$refs.video);
      this.player.loadSource(this.url); // 确保 this.videoUrl 是你的HLS流地址
      this.player.on(Hls.Events.ERROR, (event, data) => {
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              console.error('网络错误，尝试重新加载流...',data);
              console.log(this.player)
              this.player.startLoad();
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              console.error('媒体错误，尝试重新加载流...');
              console.log(this.player)
              this.player.startLoad();
              break;
            default:
              console.error('未知错误:', data.details);
              break;
          }
        }
      });
      this.player.on(Hls.Events.MANIFEST_PARSED, () => {
        video.play();
        this.loaded()
      });
    },
    getStatus() {
      return this.player ? this.player.paused() : false;
    },
    play(status) {
      this.$nextTick(() => {
        document.getElementsByTagName("video")[0].play()
        // this.player.currentTime(20)
        this.showCover = false
        this.showControl = false;
        this.$emit("beginVideo")
      })
    },
    playDefault() {
      this.$nextTick(() => {
        this.player.play();
        this.showCover = true
      })

    },
    pause() {
      this.player.pause();
    },
    toggleControl() {
      this.showControl = !this.showControl;
    },
    setControl(status) {
      this.showControl = status;
    },
    toggle() {
      let key = this.getStatus() ? 'play' : 'pause';
      this[key]();
      this.index++;
    },
    fullScreen() {
      this.isFullscreen = this.player.isFullscreen();
      if (!this.isFullscreen) {
        this.player.requestFullscreen()
      } else {
        this.player.exitFullscreen();
      }
    },
    switch(url, currTime = 0) {
      if (!url) return;
      if(!this.player){
        console.log(2222222222222222)
        this.initPlayer(url)
      }
      // try {
      //   this.player && this.player.destroy()
      //   this.player = null
      // } catch (err) {
      //   this.player = null
      // }
      // let video = this.$refs.video
      // this.$nextTick(() => {
      //   this.url = url;
      //   this.currTime = currTime;
      //   this.player = new Hls();
      //   this.player.loadSource(this.url);
      //   this.player.attachMedia(video);
      //   this.player.on(Hls.Events.MANIFEST_PARSED, () => {
      //     video.play();
      //     this.loaded()
      //   });
      // })
    },
    isMp4(url){
      return url.includes("mp4")
    },
    timeupdate() {
      this.$emit("updateTime", this.player.currentTime())
    },
    seek(time) {
      this.player.currentTime(time)
    },
    playEnd() {
      this.isPlayEnd = true;
      this.showCover = true
      this.$emit("playEnd")
    },
    fullscreenchange(e) {
      this.isFullscreen = this.player.isFullscreen();
      this.showControl = this.isFullscreen;
      // this.$refs.videoMain.children[0].appendChild(this.$refs.controlsMain)
      if (!this.$tools.isAndroid()) {
        setTimeout(() => {
          this.play()
        }, 1000)
      }
    },
    setMuted(status) {
      // this.player && this.player.muted(status)
    },
    loaded() {
      if (this.currTime) {
        this.seek(this.currTime)
      }
      // this.play(true)
      this.$emit('loaded');
    },
  }
}
</script>
<style>
/* 时间进度条 */
.h5-player-container{
  width: 100%;
  height: 100%;
  /* overflow: hidden; */
  position: relative;
  display: flex;
  align-items:center;
}

/* .video-js.vjs-fluid:not(.vjs-audio-only-mode) {
  width: 100vw;
  height: 100vw;
} */

.h5-player-container video::-webkit-media-controls-timeline {
  display: none !important;
}

/* 全屏按钮 */
.h5-player-container video::-webkit-media-controls-fullscreen-button {
  display: none !important;
  content: "\e719";
}

/* 音量按钮 */
.h5-player-container video::-webkit-media-controls-mute-button {
  display: none !important;
}

.h5-player-video-main {
  position: relative;
  z-index: 5;
  height: auto;
  width: 100%;
}

.h5-player-video-main video {
  height: auto !important;
  width: 100% !important;
  position: absolute !important;
  left: 0;
  top: -25% !important;
}


</style>