export default {
  data(){
    return {
      meetingMsg: {
        title: "提示",
        content: [],
        style: [],
        button: {},
        events: {},
        params: {},
      },
      evnets:[],
      popupKeys:{},
      showMeetingTip:false,
      popupKey:"",
      isPopupExec:false,
      closeWelcomeTip:false, // 是否关闭弹窗
    }
  },
  methods:{
    doSelect(index){
      if(this.isPopupExec) return;
      this.showMeetingTip = false;
      this.isPopupExec = true;
      this.meetingMsg.events && this.meetingMsg.events[index] && this.meetingMsg.events[index]()
      setTimeout(()=>{
        this.isPopupExec = false
      },300)
    },
    closeStreamPopup(){
      if(this.isPopupExec) return;
      this.showMeetingTip = false;
      this.isPopupExec = true;
      this.isCanPlay = false;
      setTimeout(()=>{
        this.isPopupExec = false
      },300)
    },
    initMeetingTip(popupKey,meetingMsg) {
      if (this.popupKeys[popupKey] || this.showMeetingTip) return;
      this.popupKeys[popupKey] = Date.now();
      this.popupKey = popupKey;
      this.meetingMsg = meetingMsg
      this.$nextTick(()=>{
        this.showMeetingTip = true
      })
    },
    initTestTip(){
      // 如果没有测试，则开始提示测试
      if(this.meetingInfo.phase != 0) return;

      let joinMeeting = (res) => {
        this.meetingInfo.is_test = 0;
        this.goToNextStep()
      }
      let joinTestMeeting = (res) => {
        this.meetingInfo.is_test = 1;
        this.goToNextStep()
      }
      
      let meetingMsg = this.dealWithPopupCfg('test',[joinTestMeeting,joinMeeting])
      
      if(!this.meetingInfo.meet_info.finish_test){
        meetingMsg.type = []
        meetingMsg.button = meetingMsg.button.slice(0,1)
      }
      this.popupKeys['test'] = ""
      this.initMeetingTip("test",meetingMsg)
    },
    // status 是否需要直接弹提示
    initPauseTip(status=false){
      // 暂停直播
      if(!this.meetingInfo.isPushing && !status) return;
      let meetingMsg = this.dealWithPopupCfg('pause',[this.pushStream])
      this.popupKeys['pause'] = ""
      this.initMeetingTip("pause",meetingMsg)
    },
    initWelcome(){
      // 欢迎语
      if(this.popupKeys["welcome"]) return;
      let meetingMsg = this.dealWithPopupCfg('welcome',[()=>{ this.closeWelcomeTip = true}])
      this.popupKeys['welcome'] = ""
      this.initMeetingTip("welcome",meetingMsg)
    },
    initPlayTip(){
      // 欢迎语
      if(this.popupKeys["play"]) return;
      let meetingMsg = this.dealWithPopupCfg('play',[()=>{ 
        this.isAllowPermission = true;
        this.meetingInfo.isPlaying = true;
        setTimeout(()=>{
          this.isCanPlay=false;
        },2000)
        this.$refs.h5Player.setMuted(false);
        this.$refs.h5Player.play();
        console.log(77777777777)
      }])
      this.popupKeys['play'] = ""
      this.initMeetingTip("play",meetingMsg)
    },
    testMeetEnd(){
      // 测试直播中断了多次结束
      if(this.popupKeys["testMeetEnd"]) return;
      let meetingMsg = this.dealWithPopupCfg('testMeetEnd',[uni.navigateBack])
      this.popupKeys['testMeetEnd'] = ""
      this.initMeetingTip("testMeetEnd",meetingMsg)
    },
    testMeetSuccessEnd(){
      // 测试直播断开链接小于一次结束
      if(this.popupKeys["testMeetSuccessEnd"]) return;
      let meetingMsg = this.dealWithPopupCfg('testMeetSuccessEnd',[uni.navigateBack])
      meetingMsg.content[0][0] = meetingMsg.content[0][0].replace("{countTime}",this.$tools.getShowTime(this.meetingInfo.local_tm * 1000,"hh小时mm分钟ss秒"))
      this.popupKeys['testMeetSuccessEnd'] = ""
      this.initMeetingTip("testMeetSuccessEnd",meetingMsg)
    },
    initNetworkTip(){
      // 网络中断
      if(this.popupKeys["network"]) return;
      // 重连
      // let meetingMsg = this.dealWithPopupCfg('network',[this.initWebsocket])
      // 回到上一页
      let meetingMsg = this.dealWithPopupCfg('network',[uni.navigateBack])
      this.popupKeys['network'] = ""
      this.initMeetingTip("network",meetingMsg)
    },
    initGuestNetworkTip(){
      // 观众网络中断
      if(this.popupKeys["guestNetwork"]) return;
      let meetingMsg = this.dealWithPopupCfg('guestNetwork',[uni.navigateBack])
      this.popupKeys['guestNetwork'] = ""
      this.initMeetingTip("guestNetwork",meetingMsg)
    },
    initNotAllowTip(){
      // 不能结束提示
      let meetingMsg = this.dealWithPopupCfg('notAllowEnd',[this.pushStream])
      this.popupKeys['notAllowEnd'] = ""
      meetingMsg.content[0][0] = meetingMsg.content[0][0].replace("{meetCountTime}",this.getMinute(this.meetingInfo.discuss_time))
      this.initMeetingTip("notAllowEnd",meetingMsg)
    },
    initDiscussTip(){
      let meetingMsg = this.dealWithPopupCfg('discuss',["",this.goToNextStep])
      this.popupKeys['discuss'] = ""
      meetingMsg.content[0][0] = meetingMsg.content[0][0].replace("{meetCountTime}",this.getMinute(this.meetingInfo.discuss_time)).replace("{discussCountTime}",this.getMinute(this.meetingInfo.class_time))
      this.initMeetingTip("discuss",meetingMsg)
    },
    initMeetEndTip(){
      if(this.meetingInfo.local_tm < this.meetingInfo.discuss_time){
        this.initNotAllowTip()
        return;
      }
      // 直播结束
      let meetingMsg = this.dealWithPopupCfg('allowEnd',[this.pushStream,this.goToNextStep])
      this.popupKeys['allowEnd'] = ""
      meetingMsg.content[0][0] = meetingMsg.content[0][0].replace("{countTime}",this.$tools.getShowTime(this.meetingInfo.local_tm * 1000,"hh小时mm分钟ss秒"))
      this.initMeetingTip("allowEnd",meetingMsg)
    },

    dealWithPopupCfg(popupKey,events){
      let meetingMsg = {
        title: "提示",
        ...this.$config.popups.v2[popupKey],
        events
      };
      return meetingMsg
    },

  }
}
